import React from 'react'
import img_cogent from './../assets/images/cogent.png'

const Login = () => {
  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-sm-6 offset-sm-3 py-5">
          <form
            className="form-signin"
            action={process.env.REACT_APP_SERVER_URL}
            method={process.env.NODE_ENV === 'development' ? 'get' : 'post'}
          >
            <div className="text-center mb-4">
              <img className="mb-4" src={img_cogent} alt="" height="72" />
              <h1 className="h3 mb-3 font-weight-normal">
                Security Risk Assessment Login
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim.
              </p>
            </div>

            <div className="form-label-group">
              <input
                name="username"
                type="email"
                id="inputEmail"
                className="form-control"
                placeholder="Email address"
              />
            </div>
            <br />
            <div className="form-label-group y-5">
              <input
                name="password"
                type="password"
                id="inputPassword"
                className="form-control"
                placeholder="Password"
              />
            </div>

            {/* <button>
              <Link to="/">Log In</Link>
            </button> */}

            <button
              type="submit"
              className="btn btn-lg btn-block btn-primary my-5"
              style={{ maxWidth: '500px', margin: '0 auto' }}
            >
              Log In
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
